<template>
  <div class="main">
    <div class="title">小月经期日记App服务协议</div>

    <p class="h2">1.特别提示</p>

    <p class="h3">1.1</p>

    <p class="text">
      小月经期日记App（以下简称小月日记App）运营方同意按照本协议的规定及其不时发布的操作规则向用户提供小月日记App服务。为获得小月日记App服务，小月日记App服务使用人（以下称"用户"）需在认真阅读及独立思考的基础上认可、同意本协议的全部条款（特别是以加粗方式提示用户注意的条款）并按照页面上的提示完成全部的注册程序。用户在进行注册过程中点击"同意"
      按钮（或实际使用小月日记App服务）即表示用户完全接受本服务协议及新浪网络服务使用协议、小月日记App社区公约、小月日记App商业行为规范办法、小月日记App举报投诉操作细则及小月日记App运营方公示的各项规则、规范。如用户对本服务协议或协议的任何部分存有任何异议，应终止注册程序（或停止使用小月日记App服务）。</p>

    <p class="h3">1.2</p>

    <p class="text">
      用户注册成功后，小月日记App运营方将为用户基于使用小月日记App服务的客观需要而在申请、注册小月日记App服务时，按照注册要求提供的账号开通小月日记App服务，用户有权在小月日记App运营方为其开通并同意向其提供服务的基础上使用小月日记App服务。同时，用户同意：</p>
    <ul>
      <li>1.2.1 用户应妥善保管小月日记App账号及密码。</li>

      <li>1.2.2 未经小月日记App运营方同意，用户不得擅自买卖、转让、出租任何小月日记App账号或小月日记App昵称。</li>

      <li>1.2.3 用户使用小月日记App服务过程中，须对自身使用小月日记App服务的行为，对任何由用户通过小月日记App服务发布、公开的信息，及对由此产生的任何后果承担全部责任。</li>

      <li>1.2.4 用户可以通过设置功能，自行确认和控制自己在使用小月日记App服务过程中提交、发布或显示的信息的公开方式和可见范围。</li>
    </ul>
    <p class="h3">1.3</p>

    <p class="text">用户使用小月日记App服务过程中，对自身使用小月日记App服务的行为以及通过小月日记App服务发布、公开的任何信息享有合法权利，承担相应法律责任。同时，用户同意：</p>
    <ul>
      <li>1.3.1 小月日记App运营方对小月日记App内容（小月日记App内容即指用户在小月日记App上已发布的信息，例如文字、图片、视频、音频等）享有使用权。</li>

      <li>1.3.2 未经小月日记App运营方事先书面许可，用户不得自行或授权、协助任何第三方非法抓取小月日记App内容，“非法抓取”是指采用程序或者非正常浏览等技术手段获取内容数据的行为。</li>
    </ul>
    <p class="h3">1.4</p>

    <p class="text">用户在使用小月日记App服务过程中应当严格遵守小月日记App运营方所发布的Robots协议。
      未经小月日记App运营方事先书面同意，任何用户不得以任何方式自行或委托任何第三方以违反上述规定的方式访问小月日记App平台或收集任何小月日记App内容。</p>

    <p class="h3">1.5</p>

    <p class="text">
      在法律法规允许的范围内，用户同意并授权小月日记App运营方就侵犯用户合法权益的行为（包括但不限于私自复制、使用、编辑、抄袭等行为）采取任何形式的法律行动，包括但不限于投诉、诉讼等必要的维权措施。</p>

    <p class="h3">1.6</p>

    <p class="text">
      小月日记App运营方有权直接将本服务或本协议项下权利义务委托给其关联公司或其他第三方公司进行运营、管理及履行，小月日记App运营方无需就此向用户另行获取授权。小月日记App运营方将竭尽避免前述委托或变更给用户使用小月日记App服务造成的不便，小月日记App运营方届时将尽量及时通过网站、平台、私信、邮件等方式进行通知。本协议中，关联公司是指控制某一方的、或被某一方所控制的、或与某一方共同受控制于同一实体的任何企业。控制是指直接或间接拥有该企业百分之五十（50％）以上的股权、投票权或管理权。</p>

    <p class="h3">1.7</p>

    <p class="text">为提高用户的小月日记App服务使用感受和满意度，用户同意小月日记App运营方可以对用户数据进行调查研究和分析，从而进一步优化小月日记App服务。</p>

    <p class="h2">2.服务内容</p>

    <p class="h3">2.1</p>

    <p class="text">
      小月日记App服务的具体内容由小月日记App运营方根据实际情况提供，包括但不限于用户使用小月日记App服务发布观点、评论、图片、视频、转发链接等，小月日记App运营方有权对其提供的服务或产品形态进行升级或其他调整，并将及时更新页面/告知用户。</p>

    <p class="h3">2.2</p>

    <p class="text">
      小月日记App运营方提供的部分小月日记App服务为收费网络服务，用户使用收费网络服务需要向小月日记App运营方支付一定的费用。对于收费的网络服务，小月日记App运营方会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费网络服务。如用户拒绝支付相关费用，则小月日记App运营方有权不向用户提供该等收费网络服务。</p>

    <p class="h3">2.3</p>

    <p class="text">
      用户理解，小月日记App运营方仅提供与小月日记App服务相关的技术服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网络而支付的手机费）均由用户自行负担。</p>

    <p class="h2">3.服务变更、中断或终止</p>

    <p class="h3">3.1</p>

    <p class="text">
      鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他小月日记App运营方无法控制的情形），用户同意小月日记App运营方有权随时中断或终止部分或全部的小月日记App服务（包括收费网络服务），若发生该等情形，小月日记App运营方将尽可能及时通过网页公告、系统通知、私信、短信提醒或其他合理方式通知受到影响的用户。</p>

    <p class="h3">3.2</p>

    <p class="text">
      用户理解，小月日记App运营方需要定期或不定期地对提供小月日记App服务的平台（如互联网网站、应用程序等）或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，小月日记App运营方无需为此承担任何责任，但小月日记App运营方应尽可能事先进行通告。</p>

    <p class="h3">3.3</p>

    <p class="text">
      如发生下列任何一种情形，小月日记App运营方有权随时中断或终止向用户提供本协议项下的小月日记App服务（包括收费服务和免费服务）而无需对用户或任何第三方承担任何责任，由此造成的损失由用户自行独立承担：</p>
    <ul>
      <li>3.3.1 用户提供的个人资料不真实；</li>

      <li>3.3.2 用户违反法律法规国家政策或本协议中规定的使用规则；</li>

      <li>3.3.3 用户在使用收费服务时未按规定为其所使用的收费服务支付相关服务费用；</li>

      <li>3.3.4 用户侵犯个人、企业事业单位或社会团体的合法权益，包括但不限于专利权、著作权、商标权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等；</li>

      <li>3.3.5 用户损害监管部门、国家机关及政府形象；</li>

      <li>3.3.6 用户以任何方式损害小月日记App运营方及其关联公司的商誉或信誉等合法权益；</li>

      <li>3.3.7 小月日记App运营方有其他合理理由认为需要中断或终止向用户提供小月日记App服务的。</li>
    </ul>
    <p class="h3">3.4</p>

    <p class="text">如用户在申请开通小月日记App服务后在任何连续90日内未实际使用，则小月日记App运营方有权在法律法规允许的范围内选择采取以下任何一种方式进行处理：</p>
    <ul>
      <li>3.4.1 回收用户昵称；</li>

      <li>3.4.2 回收用户账号；</li>

      <li>3.4.3 停止为该用户提供小月日记App服务。</li>
    </ul>
    <p class="h3">3.5</p>

    <p class="text">
      用户选择将小月日记App账号与小月日记App运营方合作的第三方账号进行绑定的，除用户自行解除绑定关系外，如发生下列任何一种情形，用户已绑定的第三方账号也有可能被解除绑定，小月日记App运营方无需对用户或任何第三方承担任何责任：</p>
    <ul>
      <li>3.5.1 用户违反法律法规国家政策、本协议的；</li>

      <li>3.5.2 用户违反第三方用户协议或其相关规定的；</li>

      <li>3.5.3 第三方账号所属的站方要求解除绑定的；</li>

      <li>3.5.4 第三方账号所属平台或业务已关停的；</li>

      <li>3.5.5 依据法律法规国家政策规定或主管部门要求的；</li>

      <li>3.5.6 小月日记App运营方有合理理由认为需要解除绑定的。</li>
    </ul>
    <p class="h2">4.使用规则</p>

    <p class="h3">4.1</p>

    <p class="text">用户可自行编辑注册信息中的账号名称、昵称、头像、简介等（以下简称“账号信息”），但应遵守相关法律法规，不得含有违法和不良信息。
      用户注册小月日记App账号，制作、发布、传播信息内容的，应当使用真实身份信息及个人资料，不得以虚假或冒用的居民身份信息、企业相关信息进行注册；若用户的个人资料有任何变动，用户应及时更新。
      未经相关权利人授权，用户不得以他人或其他组织机构名义注册小月日记App账号，亦不得使用引人误解的信息注册小月日记App账号，包括但不限于让人误认为该账号与其他个人或机构组织存在关联关系的名称、头像或简介等。</p>

    <p class="h3">4.2</p>

    <p class="text">新闻媒体和政务机关开设小月日记App账号的，除根据本协议约定使用小月日记App服务以外，还应遵守相关法律法规、组织规则及监管规定。</p>
    <ul>
      <li>4.2.1 媒体账号，是指具有法定新闻资质的新闻单位在小月日记App上开设的机构认证账号。媒体账号在使用小月日记App服务时应当接受新闻主管部门和网信部门的统一管理。</li>

      <li>4.2.2 政务账号，是指各级党政机关、民主党派、人民团体开设的机构认证账号。政务账号在使用小月日记App服务时应当接受网信部门的统一管理，并根据自身的职责、章程和上级单位的管理要求进行管理。</li>
    </ul>
    <p class="h3">4.3</p>

    <p class="text">用户知悉并同意，以下信息一经用户提交确认则无法更改，因内容瑕疵（包括但不限于错误，不完整、不满意等）所造成的损失及后果由用户独立承担：</p>
    <ul>
      <li>4.3.1 小月日记App账号个性域名；</li>

      <li>4.3.2 小月日记App运营方规定的确定后无法修改的账号信息。</li>
    </ul>
    <p class="h3">4.4</p>

    <p class="text">小月日记App运营方将建立健全用户信息安全管理制度、落实技术安全防控措施。小月日记App运营方将对用户使用小月日记App服务过程中涉及的用户隐私内容加以保护。
      对于用户在小月日记App中发表或存储的小月日记App内容，小月日记App运营方建议用户自行不定期备份。用户理解并同意，如用户通过使用注销账号功能而主动停止使用小月日记App服务或小月日记App服务被终止或取消的，小月日记App运营方无法向用户返还或提供任何数据。</p>

    <p class="h3">4.5</p>

    <p class="text">为维护小月日记App平台的稳定运营，确保用户体验质量，未经小月日记App运营方事先书面许可，任何人不得擅自在小月日记App平台上实施自动化行为或发布垃圾信息。</p>
    <ul>
      <li>4.5.1 自动化行为，是指未经小月日记App运营方同意，用户自行或授权、协助第三方采用自动化手段或明显异于常人的、远高于正常用户频率地发布小月日记App、评论、私信、头条文章或作出关注、点赞、抓取数据等的行为。
      </li>

      <li>4.5.2
        垃圾信息，是指未经小月日记App运营方同意，擅自使用小月日记App账号（包括通过作弊手段批量注册的小月日记App账号、普通小月日记App账号等）在小月日记App、评论、私信、账号信息、头条文章、群聊内容中发布的营销信息、无意义信息或买卖粉丝信息。
      </li>
    </ul>
    <p class="text">
      用户知悉并同意，小月日记App运营方有权根据技术规则通过检测验证等方式判断用户账号行为是否构成自动化行为、用户账号所发布的信息是否为垃圾信息，并采取相关措施予以处理。所有处理措施所依据的数据及信息（包括但不限于账号操作记录、小月日记App内容、转评赞等）均以小月日记App平台后台记录为准。</p>

    <p class="h3">4.6</p>

    <p class="text">
      由于小月日记App服务的存在前提是用户在申请开通小月日记App服务的过程中所提供的账号，用户不应将其账号、密码转让或出借予他人使用。如用户发现其账号或小月日记App服务遭他人非法使用，应立即通知小月日记App运营方。因黑客行为或用户的保管疏忽等非小月日记App运营方原因导致账号、密码及小月日记App服务遭他人非法使用的，由用户自行承担相关责任。</p>

    <p class="h3">4.7</p>

    <p class="text">
      用户同意小月日记App运营方在提供小月日记App服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在小月日记App平台网站的任何页面上投放广告），并且，用户同意接受小月日记App运营方通过电子邮件、私信或其他方式向用户发送商品促销或其他相关商业信息。</p>

    <p class="h3">4.8</p>

    <p class="text">
      为了更好地促进信息分享及宣传推广，用户授权小月日记App运营方可在小月日记App及其关联产品和服务上使用小月日记App内容，以及为宣传推广之目的将上述内容许可给第三方使用。用户对小月日记App运营方及其关联公司的前述授权并不改变用户发布内容的所有权及知识产权归属，也并不影响用户行使其对发布内容的合法权利。</p>

    <p class="h3">4.9</p>

    <p class="text">用户在使用小月日记App服务的过程中应文明发言，并依法尊重其它用户的人格权与身份权等人身权利，共同建立和谐、文明、礼貌的网络社交环境。</p>

    <p class="h3">4.10</p>

    <p class="text">用户在使用小月日记App服务过程中，必须遵循以下原则：</p>
    <ul>
      <li>4.10.1 不得违反中华人民共和国法律法规及相关国际条约或规则；</li>

      <li>4.10.2 不得违反与网络服务、小月日记App服务有关的网络协议、规定、程序及行业规则；</li>

      <li>4.10.3 不得进行任何可能对互联网或移动网正常运转造成不利影响的行为；</li>

      <li>4.10.4 不得上传、展示或传播任何不实虚假、冒充性的、骚扰性的、中伤性的、攻击性的、辱骂性的、恐吓性的、种族歧视性的、诽谤诋毁、泄露隐私、色情淫秽、恶意抄袭、暴力、血腥、自杀、自残的或其他任何非法的信息资料；
      </li>

      <li>4.10.5 不得侵犯任何个人、企业事业单位或社会团体的合法权益，包括但不限于专利权、著作权、商标权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等；</li>

      <li>4.10.6 不得以任何方式损害各级国家机关及政府形象；</li>

      <li>4.10.7 不得以任何方式损害小月日记App运营方及其关联公司的商誉或信誉等合法权益；</li>

      <li>4.10.8 不得从事其他任何影响小月日记App运营方正常运营、破坏小月日记App经营模式或其他有害小月日记App生态的行为；</li>

      <li>4.10.9 不得为其他任何非法目的而使用小月日记App服务。</li>
    </ul>
    <p class="h3">4.11</p>

    <p class="text">
      针对某些特定的小月日记App服务的使用规则及说明，小月日记App运营方通过各种方式（包括但不限于网页公告、系统通知、私信、短信提醒等）作出的任何声明、通知、警示等内容均视为本协议的一部分，用户如使用该等小月日记App服务，视为用户同意该等声明、通知、警示的内容。</p>

    <p class="h3">4.12</p>

    <p class="text">
      小月日记App运营方有权对用户使用小月日记App服务的行为及信息进行审查、监督及处理，包括但不限于用户信息（账号信息、个人信息等）、发布内容（位置、文字、图片、音频、视频、商标、专利、出版物等）、用户行为（构建关系、@信息、评论、私信、参与话题、参与活动、营销信息发布、举报投诉等）等范畴。</p>

    <p class="h3">4.13</p>

    <p class="text">
      如用户在使用小月日记App服务的过程中发现其它用户上传违法侵权等内容，用户可直接点击"举报"按键进行举报，相关人员会尽快核实并进行处理；如涉及姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等人身权益纠纷的处理，用户可依据相关法律法规并参照小月日记App有关公告所公示的方式进行处理；如用户认为上述方法无法解决遇到的问题、或用户觉得有必要向司法行政机关寻求帮助的，用户可尽快向相关机关反馈，小月日记App运营方将依法配合司法机关的调查取证工作。</p>

    <p class="h2">5.知识产权</p>

    <p class="h3">5.1</p>

    <p class="text">小月日记App运营方是小月日记App平台和小月日记App产品的所有权及知识产权权利人。</p>

    <p class="h3">5.2</p>

    <p class="text">
      上述小月日记App产品指的是小月日记App运营方、小月日记App运营方关联公司、或其授权主体等通过小月日记App平台为用户提供的包括但不限于信息发布分享、关系链拓展、便捷辅助工具、平台应用程序、公众开放平台等功能、软件、服务等。</p>

    <p class="h3">5.3</p>

    <p class="text">
      小月日记App运营方是小月日记App平台及小月日记App产品中所有信息内容的所有权及知识产权权利人。前述信息内容包括但不限于程序代码、界面设计、版面框架、数据资料、账号、文字、图片、图形、图表、音频、视频等，除按照法律法规规定应由相关权利人享有权利的内容以外。</p>

    <p class="h3">5.4</p>

    <p class="text">
      用户在使用小月日记App平台的过程中，可能会使用到由第三方开发的在小月日记App平台运行的功能、软件或服务，用户除遵守本协议相关规定以外，还应遵守第三方相关规定，并尊重第三方权利人对其功能、软件、服务及其所包含内容的相关权利。</p>

    <p class="h3">5.5</p>

    <p class="text">鉴于以上，用户理解并同意：</p>
    <ul>
      <li>5.5.1 未经小月日记App运营方及相关权利人同意，用户不得对上述功能、软件、服务进行反向工程 （reverse
        engineer）、反向编译（decompile）或反汇编（disassemble）等；同时，不得将上述内容或资料在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者用于其他任何目的；</li>

      <li>5.5.2 在尽商业上的合理努力的前提下，小月日记App运营方并不就上述功能、软件、服务及其所包含内容的延误、不准确、错误、遗漏或由此产生的任何损害，以任何形式向用户或任何第三方承担任何责任；</li>

      <li>5.5.3 小月日记App运营方并不对上述任何由第三方提供的功能、软件、服务或内容进行任何保证性的、或连带性的承诺或担保，由此产生的任何纠纷、争议或损害，由用户与第三方自行解决，小月日记App运营方不承担任何责任；</li>

      <li>5.5.4 为更好地维护小月日记App生态，小月日记App运营方保留在任何时间内以任何方式处置上述由小月日记App运营方享受所有权及知识产权的产品或内容，包括但不限于修订、屏蔽、删除或其他任何法律法规允许的处置方式。</li>
    </ul>
    <p class="h2">6.隐私保护</p>

    <p class="h3">6.1</p>

    <p class="text">保护用户隐私和其他个人信息是小月日记App的一项基本政策，具体内容详见小月日记App个人信息相关保护政策。</p>

    <p class="h2">7.免责声明</p>

    <p class="h3">7.1</p>

    <p class="text">用户在使用小月日记App服务的过程中应遵守国家法律法规及政策规定，因其使用小月日记App服务而产生的行为后果由用户自行承担。</p>

    <p class="h3">7.2</p>

    <p class="text">
      用户通过小月日记App服务发布的任何信息，及通过小月日记App服务传递的任何观点不代表小月日记App之立场，小月日记App亦不对其完整性、真实性、准确性或可靠性负责。用户对于可能会在小月日记App上接触到的非法的、非道德的、错误的或存在其他失宜之处的信息，及被错误归类或是带有欺骗性的发布内容，应自行做出判断。在任何情况下，因前述非正当信息而导致的任何损失或伤害，应由相关行为主体承担全部责任。</p>

    <p class="h3">7.3</p>

    <p class="text">鉴于外部链接指向的网页内容非小月日记App运营方实际控制，因此小月日记App运营方无法保证为向用户提供便利而设置的外部链接的准确性和完整性。</p>

    <p class="h3">7.4</p>

    <p class="text">对于因不可抗力或小月日记App运营方不能控制的原因造成的小月日记App服务中断或其它缺陷，小月日记App运营方不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>

    <p class="h3">7.5</p>

    <p class="text">小月日记App运营方对其向用户所提供的小月日记App产品及服务依法承担相关责任，但法律另有规定或另有约定的除外。</p>

    <p class="h3">7.6</p>

    <p class="text">
      用户知悉并同意，小月日记App运营方可能会与第三方合作向用户提供产品（包括但不限于游戏、第三方应用等）并由第三方向用户提供该产品的升级、维护、客服等后续工作，由该等第三方对该产品的质量问题或其本身的原因导致的一切纠纷或用户损失承担责任，用户在此同意将向该第三方主张与此有关的一切权利和损失。</p>

    <p class="h3">7.7</p>

    <p class="text">
      除另有约定外，小月日记App运营方提供的产品或服务（包括但不限于游戏物品及道具），如未标明使用期限、或者其标明的使用期限为“永久”、“无限期”或“无限制”的，则其使用期限为自用户开始使用该产品或服务至该产品或服务在小月日记App下线之日为止。</p>

    <p class="h2">8.违约责任</p>

    <p class="h3">8.1</p>

    <p class="text">如因小月日记App运营方违反有关法律、法规或本协议项下的任何条款而给用户造成损失的，因此给用户造成的损害赔偿责任由小月日记App运营方承担。</p>

    <p class="h3">8.2</p>

    <p class="text">
      用户同意保障和维护小月日记App运营方及其关联公司及其他用户的合法权益，如因用户违反有关法律、法规或本协议项下的任何条款而给小月日记App运营方及其关联公司或任何其他任何第三方造成损失，用户同意承担由此造成的损害赔偿责任。</p>

    <p class="h3">8.3</p>

    <p class="text">
      如小月日记App运营方发现或收到第三方举报或投诉获知，用户存在或涉嫌违反本协议第1条（特别提示）、或第4条（使用规则）的，小月日记App运营方或其授权主体有权依据其合理判断不经通知立即采取一切必要措施以减轻或消除用户行为造成的影响，并将尽可能在处理之后对用户进行通知。由此造成的损失及后果（包括但不限于错过推广时机、丧失营销收入等）由用户自行独立承担。</p>

    <p class="h3">8.4</p>

    <p class="text">
      除本协议另有约定外，如小月日记App运营方发现或收到第三方举报或投诉获知，用户存在或涉嫌违反本协议中约定的义务、保证、承诺或其他条款，用户应在小月日记App运营方指定期限内予以纠正并消除影响；若用户未在前述时限内予以纠正的，小月日记App运营方或其授权主体有权依据其合理判断立即采取一切必要措施以减轻或消除用户行为造成的影响，并将尽可能在处理之后对用户进行通知。由此造成的损失及后果（包括但不限于错过推广时机、丧失营销收入等）由用户自行独立承担。</p>

    <p class="h3">8.5</p>

    <p class="text">本协议8.3、8.4中所述的“一切必要措施”包括但不限于以下一项或多项：</p>
    <ul>
      <li>8.5.1 更改、删除或屏蔽相关内容；</li>

      <li>8.5.2 警告违规账号、账号禁言；</li>

      <li>8.5.3 冻结用户账户资金，用于弥补用户给小月日记App运营方及其关联公司、他人造成的损失；</li>

      <li>8.5.4 变更、限制或禁止违规账号部分或全部功能；</li>

      <li>8.5.5 暂停、限制或终止用户使用小月日记App服务的权利、注销用户账号等；</li>

      <li>8.5.6 向有关监管部门或国家机关报告；</li>

      <li>8.5.7 其他小月日记App运营方认为合理的措施。</li>
    </ul>
    <p class="h2">9.协议修改</p>

    <p class="h3">9.1</p>

    <p class="text">
      小月日记App运营方有权随时修改本协议的任何条款，一旦本协议的内容发生变动，小月日记App运营方将会在小月日记App平台上公布修改之后的协议内容，小月日记App运营方也可选择通过其他适当方式（比如系统通知）向用户通知修改内容。</p>

    <p class="h3">9.2</p>

    <p class="text">如果不同意小月日记App运营方对本协议相关条款所做的修改，用户有权停止使用小月日记App服务。如果用户继续使用小月日记App服务，则视为用户接受小月日记App运营方对本协议相关条款所做的修改。</p>

    <p class="h2">10.通知送达</p>

    <p class="h3">10.1</p>

    <p class="text">
      本协议项下小月日记App运营方对于用户所有的通知均可通过网页公告、电子邮件、系统通知、小月日记App管理账号主动联系、私信、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</p>

    <p class="h3">10.2</p>

    <p class="text">用户对于小月日记App运营方的通知应当通过小月日记App运营方对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</p>

    <p class="h2">11.法律适用</p>

    <p class="h3">11.1</p>

    <p class="text">本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律。</p>

    <p class="h3">11.2</p>

    <p class="text">如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向北京市海淀区人民法院提起诉讼。</p>

    <p class="h2">12.其他规定</p>

    <p class="h3">12.1</p>

    <p class="text">如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>

    <p class="h3">12.2</p>

    <p class="text">本协议中的标题仅为方便而设，在解释本协议时应被忽略，不能作为本协议条款解释的依据。</p>

  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.main {
  font-size: 14px;
  padding: 10px 15px;
  background-color: #ffffff;
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
}

.h2 {
  font-size: 16px;
  font-weight: 700;
}

.h3 {
  font-size: 14px;
  font-weight: 700;
}
</style>
